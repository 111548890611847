import React, { Component } from "react";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import "./WaitingForm.scss";

class WaitingForm extends Component {
  render() {
    const { t } = this.props;

    return (
      <div className="embedded-form">
        <Header />

        <div className="row">
          <div className="col-12">
            <div className="page-description">{t("waitingFormLoading")}</div>
          </div>
          <div className="col-12 text-center">
            <img
              className="waiting-logo"
              src="assets/icon-512.png"
              alt="Feedybacky waiting logo"
            />
          </div>
        </div>

        <Footer
          text={[
            t("footerPartOfSystem"),
            <a
              key="linkFeedybacky"
              href={process.env.REACT_APP_FRONTEND_URL}
              target="_blank"
              rel="noreferrer"
            >
              Feedybacky
            </a>,
            t("footerHandledBy"),
            <a
              key="linkCompany"
              href="http://www.wildasoftware.pl"
              target="_blank"
              rel="noreferrer"
            >
              Wilda Software
            </a>,
            ".",
          ]}
        />
      </div>
    );
  }
}

export default WaitingForm;
