import React, { Component } from "react";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";

class FailedForm extends Component {
  render() {
    const { t } = this.props;

    return (
      <div className="embedded-form">
        <Header />

        <div className="row">
          <div className="col-12">
            <div className="page-description danger-text">
              {t("failedFormInfoPreLink")}{" "}
              <a
                href={process.env.REACT_APP_FRONTEND_URL}
                target="_blank"
                rel="noreferrer"
              >
                Feedybacky
              </a>{" "}
              {t("failedFormInfoPostLink")}
            </div>
          </div>
        </div>

        <Footer
          text={[
            t("footerPartOfSystem"),
            <a
              key="linkFeedybacky"
              href={process.env.REACT_APP_FRONTEND_URL}
              target="_blank"
              rel="noreferrer"
            >
              Feedybacky
            </a>,
            t("footerHandledBy"),
            <a
              key="linkCompany"
              href="http://www.wildasoftware.pl"
              target="_blank"
              rel="noreferrer"
            >
              Wilda Software
            </a>,
            ".",
          ]}
        />
      </div>
    );
  }
}

export default FailedForm;
