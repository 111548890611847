import "./Navigation.scss";
//import Select from 'react-select';

export function Navigation(props) {
  /*   
    const langStyles = {
        option: (base, state) => ({
            ...base,
            width: '6rem',
            color: state.isSelected ? '#f5f5f5' : '#018fa7',
            backgroundColor: state.isSelected ? '#00a3b4' : '#f5f5f5',
            '&:hover': {
                backgroundColor: '#018fa7',
                color: '#f5f5f5',
            },
        }),
        control: (base, state) => ({
            ...base,
            width: '6rem',
            color: '#018fa7',
            backgroundColor: '#f5f5f5',
            boxShadow: 'none',
            border: 'none',
        }),
        container: (base, state) => ({
            ...base,
            width: '6rem',
            backgroundColor: '#f5f5f5',
            borderColor: '#018fa7',
            textAlign: 'left',
            position: 'fixed',
            right: '3rem',
        }),
        singleValue:(base, state) => ({
            ...base,
            color: '#018fa7',
            backgroundColorcolor: '#018fa7',
            borderColor: '#018fa7',
        }),
        menu:(base, state) => ({
            ...base,
            backgroundColor: '#f5f5f5',
        }),
        indicatorSeparator:(base, state) => ({
            ...base,
            backgroundColor: '#f5f5f5',
        }),
    }

    const options = [
	{ label: 'PL', value:'pl-PL' },
	{ label: 'EN', value:'en' }, 
	];
	
	//Dodać wykrywanie aktualnego języka
    let defaultValue = { label: 'LANG', value: 'LANG' };
	*/
  return (
    <div className="right-corner">
      <img src={"assets/logo_feedybacky.png "} alt="Feedybacky Embedded Form" />
    </div>
  );
  //Przenieść na linię 58     <Select options={ options } defaultValue={ defaultValue } onChange={ (lang) => i18n.changeLanguage(lang.value) } isSearchable={ false } styles={ langStyles }/>
}
