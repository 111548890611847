import React, { useState, useEffect, Suspense } from "react";
import { useParams } from "react-router-dom";
import "./App.scss";
import ActiveForm from "./pages/ActiveForm";
import FailedForm from "./pages/FailedForm";
import WaitingForm from "./pages/WaitingForm";
import { Navigation } from "./components/Navigation";
import { withTranslation } from "react-i18next";

const WaitingFormComponent = withTranslation()(WaitingForm);
const ActiveFormComponent = withTranslation()(ActiveForm);
const FailedFormComponent = withTranslation()(FailedForm);

const App = () => {
  const { key } = useParams();
  const [state, setState] = useState({
    isError: false,
    project: null,
    isPending: true,
  });

  useEffect(() => {
    if (key) {
      fetch(`${process.env.REACT_APP_BACKEND_URL}project/fef/${key}`)
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.statusCode === 404) {
              setState({
                isError: true,
                errorMessage: "404 Not Found",
                isPending: false,
              });
            } else {
              setState({
                project: result,
                isPending: false,
              });
            }
          },
          (error) => {
            setState({
              isError: true,
              errorMessage: error,
              isPending: false,
            });
          }
        );
    } else {
      setState({
        isError: true,
        isPending: false,
      });
    }
  }, [key]);

  const { project, isError } = state;

  let content = <WaitingFormComponent />;

  if (project) {
    content = <ActiveFormComponent project={project} />;
  } else if (isError) {
    content = <FailedFormComponent />;
  }

  return (
    <div id="App">
      <Navigation />
      <div className="container container-content">
        <Suspense fallback="loading">{content}</Suspense>
      </div>
    </div>
  );
};

export default App;
